$pg-404-bg: #d6d6d6;
$pg-404-text: #65646a;

.page-404 {
    background: $pg-404-bg;
    height: 100%;

    .rounded {
        border-radius: 8px;
        padding: 10px;
        background: $pg-404-bg;
        margin-bottom: 15px;

        &-title, &-subtitle {
            margin: 0;
        }

        &-title {
            margin: 0;
            font-size: 160px;
            color: #fff;
        }

        &-subtitle {
            color:$pg-404-text;
            font-size: 21px;
            text-transform: uppercase;

        }
    }

    p {
        color: $pg-404-text;
        font-size: 14px;
        margin: 0 0 15px;

        a {
            color: inherit;
            text-decoration: underline;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    &-container {
        position: relative;

        &.whole-page {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &-404 {
        border-radius: 25px;
        background: #fff;
        padding: 20px;
        width: 360px;
        max-width: 100%;
    }
}