@import "bootstrap";
@import "pages/404";

/*fonts*/
@font-face {
    font-family: 'dinengschrift';
    src: url('./../../public/ui/fonts/dinengschrift-alternate-webfont.eot');
    src: url('./../../public/ui/fonts/dinengschrift-alternate-webfont.eot?#iefix') format('embedded-opentype'),
    url('./../../public/ui/fonts/dinengschrift-alternate-webfont.woff2') format('woff2'),
    url('./../../public/ui/fonts/dinengschrift-alternate-webfont.woff') format('woff'),
    url('./../../public/ui/fonts/dinengschrift-alternate-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'din_pflregular';
    src: url('./../../public/ui/fonts/din_n__-webfont.eot');
    src: url('./../../public/ui/fonts/din_n__-webfont.eot?#iefix') format('embedded-opentype'),
    url('./../../public/ui/fonts/din_n__-webfont.woff2') format('woff2'),
    url('./../../public/ui/fonts/din_n__-webfont.woff') format('woff'),
    url('./../../public/ui/fonts/din_n__-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'din_pflbold';
    src: url('./../../public/ui/fonts/din_b__-webfont.eot');
    src: url('./../../public/ui/fonts/din_b__-webfont.eot?#iefix') format('embedded-opentype'),
    url('./../../public/ui/fonts/din_b__-webfont.woff2') format('woff2'),
    url('./../../public/ui/fonts/din_b__-webfont.woff') format('woff'),
    url('./../../public/ui/fonts/din_b__-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

body {
    background: #cccccc url(./../../public/ui/css/img/bg.png) top repeat-x;
    font-family: Arial, helvetica, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #464646;
    @media (max-width: $screen-sm-min) {
        background-image: none;
    }
}

/*fixed footer*/
$footerheight: 57px;
body.footer-fixed {
    height: calc(100% - #{$footerheight});
    height: -webkit-calc(100% - 67px);

    @media (max-width: $screen-sm-min) {

    }
}

html.footer-fixed {
    height: 100%;
}
html.footer-fixed {
    min-height: 100%;
    position: relative;
}
.footer-fixed {
    .main-container {
        height: calc(100% - 118px);
    }
}


.container {
    background: transparent;
}
.container-inner {
    padding: 0;

    @media (max-width: 1024px) {
        padding: 15px;
    }

    @media (max-width: $screen-md-max) {
        padding: 0;
    }
}
.main-container {
    @media (max-width: $screen-sm-min) {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .container-inner {
        margin-bottom: 20px;
        padding-top: percentage(337px / 940px);;
        background-color: #f5f5f5;
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100% auto;
        @include box-shadow(0px 1px 5px 0px rgba(0,0,0,0.15));

        .page-content {
            margin-top: -84px;
            padding: 36px 20px 36px;
            background-color: rgba(255,255,255,0.8);

            @media (max-width: $screen-sm-min) {
                margin-top: 0;
            }
        }
    }
}

.main-container .container-inner { background-image: url(./../../public/ui/css/img/header_cegunkrol.jpg); }
.rolunk .main-container .container-inner { background-image: url(./../../public/ui/css/img/header_cegunkrol.jpg); }
.registration .main-container .container-inner { background-image: url(./../../public/ui/css/img/header_regisztracio.jpg); }
.gyik .main-container .container-inner { background-image: url(./../../public/ui/css/img/header_gyik.jpg); }
.folyamatleiras .main-container .container-inner { background-image: url(./../../public/ui/css/img/header_folyamatleiras.jpg); }
.kapcsolatok .main-container .container-inner { background-image: url(./../../public/ui/css/img/header_kapcsolatok.jpg); }
.tajekoztatasok .main-container .container-inner { background-image: url(./../../public/ui/css/img/header_tajekoztatasok.jpg); }
.hatosagi-hirek .main-container .container-inner { background-image: url(./../../public/ui/css/img/header_hatosagi_hirek.jpg); }
.sajtomegjelenesek .main-container .container-inner { background-image: url(./../../public/ui/css/img/header_sajtomegjelenesek.jpg); }
.karrier .main-container .container-inner { background-image: url(./../../public/ui/css/img/header_karrier.jpg); }


.home .main-container {
    @media (max-width: $screen-sm-min) {
        padding: 10px 0;
    }

    .container-inner {
        margin-bottom: 0;
        padding-top: 0;
        background: none;
        @include box-shadow(none);

        @media (max-width: 768px) {
            padding-right: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
        }
    }
}

.header-top {
    margin-top: 19px;
    min-height: 98px;
    padding-bottom: 8px;
    font-family: 'dinengschrift';
    a {
        color: #000;
        font-size: 18px;
        display: block;
    }
    span {
        font-size: 14px;
        display: block;
        line-height: 14px;
    }
    .header-info-block {
        padding-top: 4px;
        color: #fff;
        font-size: 18px;
        line-height: 22px;
        @media (max-width: $screen-sm-min) {
            padding-top: 8px;
        }

    }
    .call-to-info {
        color: #fff;
        display: inline-block;
        margin-top: 0px;
        @media (max-width: $screen-sm-min) {
            margin-top: 0px;
        }
    }

    @media (max-width: $screen-sm-min) {
        margin-top: 0;
        padding-top: 10px;
        background-color: #0039a1;
    }
}
.logo {
    background: url(./../../public/ui/css/img/odbe_logo.png) no-repeat;
    width: 326px;
    height: 79px;
    display: block;
    span {
        display: none;
    }
    @media (max-width: 350px) {
        background-size: 95% auto;
        width: 287px;
    }
}
.nd-logo {
    background: url(./../../public/ui/css/img/nd_logo.png) no-repeat;
    width: 110px;
    height: 32px;
    display: block;
    position: absolute;
    right: 15px;
    top: -3px;
    @media (max-width: $screen-sm-min) {
        position: relative;
        right: 0;
        top: 0;
    }

    /*egyelore le kell venni*/
    display: none !important;

}
/*Navbar*/
.navbar {
    margin: 0;
    background: none;
    border: 0;
    font-family: 'dinengschrift';
    min-height: 60px;
}

.navbar-nav {
    margin: 8px 0 0 0px;

    @media (max-width: $screen-sm-min) {
        margin-left: 0;
    }

    li {
        margin-right: 1px;
        @media (max-width: $screen-sm-min) {
            border-bottom: 1px solid #fff;
        }
    }

    .open .dropdown-menu > li > span.inactive {
        padding: 5px 15px 5px 25px;
    }

    > li > .dropdown-menu li  > span.inactive {
        line-height: 20px;
        display: block;
        padding: 1px 13px;
        font-size: 12px;
        color: #000000;
    }


}

.navbar-collapse {
    padding: 0;
    @media (max-width: $screen-sm-min) {
        padding: 0;
    }
}

.navbar-brand {
    display: none;
}
.navbar-default .navbar-toggle .icon-bar {
    background: #fff;
}
.navbar-default {
    .container-inner {
        background: #498aff;
    }
}
.navbar-header {


    @media (max-width: $screen-sm-min) {
        background: #2773fc;

        .navbar-brand {
            display: block;
            color: #fff;
            font-size: 22px;

            &:hover {
                color: #fff;
            }
        }
    }
    @media (max-width: $screen-sm-min) {
        margin-top: 10px;
    }
}
/*navbar justy*/
.navbar-nav {
    width: 100%;
    margin-top: 0;
}

.navbar-nav > li {
    display: table-cell;
    width: 7%;
    float: none;

    &:last-child {
        border: none;
    }
    @media (max-width: $screen-sm-min) {
        display: block;
        width: 100%;
    }
}



.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > span.inactive {
    color: #fff;
    text-align: center;
    padding: 19px 0 18px;
    line-height: 23px;
    background: #498aff;
    position: relative;
    font-family: "din_pflregular";
    font-size: 17px;
    white-space: nowrap;
    text-transform: uppercase;

    @media (max-width: $screen-sm-min) {
        padding: 10px;
        text-align: left;
    }

}

.navbar-default .navbar-nav > li > span.inactive {
    opacity: 0.5;
    display: block;
}

.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:active {
    color: #fff;
}

.navbar-default .navbar-nav > li.active > a:link, .navbar-default .navbar-nav > li.active > a:hover, .navbar-default .navbar-nav > li.active > a:focus {
    background: #0039a1;
    color: #fff;

    @media (max-width: $screen-sm-min) {
        background: #dddddd;
        color: #65646a;
    }
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #fff;
    background-color: #0039a1;

    @media (max-width: $screen-sm-min) {
        background: #dddddd;
        color: #65646a;
    }
}
/*Navbar dropdown*/
.navbar-nav > li > .dropdown-menu {
    padding: 0;
    background: rgba(36,97,208,0.9);
    min-width: 0;
    border: 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;

    li {
        margin: 0;
        a {
            display: block;
            color: #fff;
            padding: 9px 23px;
            line-height: 23px;
            font-family: "din_pflregular";
            font-size: 18px;
            white-space: nowrap;
            text-transform: uppercase;
        }
        &:hover {
            a {
                background: #0039a1;
            }
        }
    }
}

@media (max-width: $screen-sm-min) {
    .navbar-nav .open .dropdown-menu {
        background: #dddddd;
    }
}

@media (min-width: $screen-sm-min) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
    .navbar-default .navbar-nav > li:hover > a {
        background: #2461d0;
    }
}

/*Sub-nav*/

ul.sub-nav {

    position: relative;
    top: 34px;
    padding-bottom: 30px;

    &:after {
        display: block;
        content: '';
        position: absolute;
        right: 0;
        top: 8px;
        height: 100%;
        width: 1px;
        background: #b1b2b4;

        @media (max-width: $screen-sm-max) {
            right: -15px;
        }

    }

    li {
        a {
            line-height: 22px;
            font-size: 14px;
            color: #0039a1;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }

            &.actual {
                font-weight: bold;
            }
        }

        span.inactive{
            line-height: 22px;
            font-size: 14px;
            color: #000000;

        }
    }

}

/*sub menu actuals*/
body.rolunk .sub-nav #menu_rolunk, body.vezetoink .sub-nav #menu_vezetoink, body.jogszabalyok .sub-nav #menu_jogszabalyok, body.hirek .sub-nav #menu_hirek, body.sajtomegjelenesek .sub-nav #menu_sajtomegjelenesek, body.tajekoztatasok .sub-nav #menu_tajekoztatasok {
    font-weight: bold;
}

/*Footer*/
footer {
    //font-family: 'dinengschrift';
    color: #fff;
    height: 57px;
    &.fixed {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    a, span.inactive {
        margin-left: 27px;
        color: #fff;
        font-size: 15px;
        font-family: Arial, Helvetica, sans-serif;
        &:hover, &:focus {
            color: #fff;
        }

        &:first-child {
            margin-left: 0;
        }

        @media (max-width: $screen-sm-max) {
            margin-left: 20px;
        }
        @media (max-width: 320px) {
            margin-left: 7px;
        }
    }
    span.inactive {
        opacity: 0.4;
    }

    .container {
        line-height: 21px;
        position: relative;
        font-size: 14px;

        .container-inner {
            padding: 0 26px 0 36px;
            background: #498aff;
        }
    }
    .footer-menu {
        padding: 19px 0;
        font-family: "din_pflregular";
        font-size: 18px;

        &.text-right {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 15px;
        }
    }
    @media (max-width: $screen-sm-max) {
        .col-xs-12 {
            text-align: center;
        }

        .container .container-inner {
            padding: 0 20px;
        }
    }
}

/*content*/
.main-container {
    h1 {
        font-size: 26px;
        margin-bottom: 26px;
    }
    p {
        margin-bottom: 15px;
    }
    a {
        color: #0039a1;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.customer-reg {
    h2 {
        border-bottom: 1px solid #013ca6;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 6px;
        padding-bottom: 1px;
    }
}

.message {
    margin-bottom: 20px;
    border: 1px solid #b5b5b5;
    background: #dff0d8 url(./../../public/ui/css/img/info_inform_icon.png) no-repeat 20px center;
    border-radius: 8px;
    padding: 16px 15px 16px 62px;
    position: relative;
    font-size: 14px;

    &.error {
        background: #f2dede url(./../../public/ui/css/img/info_warning_icon.png) no-repeat 20px center;
    }
}

@media (max-width: $screen-sm-min) {
    .sm-text-left {
        text-align: left;
    }
}


/*Contents*/
.sign {
    div {
        padding-right: 15px;
        font-style: italic;
        line-height: 22px;
        span {
            font-size: 14px;
            display: block;
        }

        &.last {
            margin-right: 25px;
        }
    }
}
.box-block {
    position: relative;
    z-index: 1;

    + & {
        margin-top: 45px;
    }

    @media (max-width: $screen-sm-min) {
        .box {
            height: auto !important;
        }
    }
}
.box {
    margin-bottom: 20px;
    background-color: #fff;
    position: relative;
    @include box-shadow(0px 1px 5px 0px rgba(0,0,0,0.15));
    padding: 26px 28px 20px 20px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    @media (max-width: $screen-sm-min) {
        width: 100%;
        float: none !important;
        margin-bottom: 40px;
    }

    .box-title {
        font-family: 'din_pflbold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: 18px;
        color: #0039a1;
        line-height: 22px;
    }
    .box-title-2 {
        margin-top: 10px;
    }
    .box-content {
        strong, b {
            font-weight: bold;
        }
        margin-top: 17px;
        overflow: hidden;
        @media (max-width: $screen-sm-min) {
            height: auto;
            overflow: visible;
        }

        p {
            margin-bottom: 15px;
        }
    }
    .read-more {
        text-decoration: none;
        font-weight: bold;

        &:after {
            content: "\00bb";
            display: inline-block;
            margin-left: 6px;
            font-size: 18px;
        }
    }
    &.wide {
        width: 100%;

        &.first {
            margin-bottom: 30px;
        }
        .box-content {
            height: auto;
        }
    }
}

.box-bottom-left, .box-bottom-right {
    padding-top: percentage(146/460);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% auto;
}

.box-bottom-left { background-image: url(./../../public/ui/css/img/box_left.jpg); }
.box-bottom-right { background-image: url(./../../public/ui/css/img/box_right.jpg); }


.owl-nav > div {
    margin: auto;
    position: absolute;
    top: -35px;
    bottom: 0;
    width: 30px;
    height: 30px;
    background: rgba(0,57,161,0.8);

    &:before {
        margin: auto;
        content: "";
        border-style: solid;
        border-width: 8px 14px;
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        bottom: 0;
    }

    @media (max-width: $screen-sm-min) {
        top: 0;
    }
}

.owl-prev {
    left: 0;

    &:before {
        border-color: transparent #fff transparent transparent;
        left: -6px;
    }
}

.owl-next {
    right: 0;

    &:before {
        border-color: transparent transparent transparent #fff;
        right: -6px;
    }
}

.slider {
    @media (max-width: $screen-xs-min - 1) {
        display: none !important;
    }
}

.slide {
    padding-top: percentage(337px / 940px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
}

.slide-1 { background-image: url(./../../public/ui/css/img/slide_1.jpg); }
.slide-2 { background-image: url(./../../public/ui/css/img/slide_2.jpg); }
.slide-3 { background-image: url(./../../public/ui/css/img/slide_3.jpg); }
.slide-4 { background-image: url(./../../public/ui/css/img/slide_4.jpg); }

.slide-caption {
    position: absolute;
    top: percentage(120/337);
    left: percentage(80/940);
    max-width: percentage(380/940);

    @media (max-width: $screen-md-min) {
        max-width: percentage(780/940);
    }

    @media (max-width: $screen-sm-min) {
        top: percentage(80/337);
    }

    @media (max-width: $screen-xs-min - 1) {
        top: percentage(30/337);
    }
}

.slide-title {
    padding: 11px 20px 9px;
    display: inline-block;
    background-color: #fff;
    font-size: 24px;
    font-weight: bold;
    color: #0039a1;

    &:link, &:visited, &:hover, &:active {
        text-decoration: none;
    }

    @media (max-width: $screen-md-min) {
        font-size: 20px;
    }

    @media (max-width: $screen-sm-min) {
        padding: 12px 8px;
        font-size: 18px;
        line-height: 20px;
    }

    @media (max-width: $screen-xs-min - 1) {
        padding: 8px;
    }
}

.slide-text {
    padding: 12px 22px;
    display: block;
    background-color: rgba(255,255,255,0.9);
    font-size: 16px;

    &:link, &:visited, &:hover, &:active {
        text-decoration: none;
    }

    &:after {
        content: "\00bb";
        display: inline-block;
        margin-left: 6px;
        font-size: 18px;
    }

    @media (max-width: $screen-md-min) {
        font-size: 14px;
    }

    @media (max-width: $screen-sm-min) {
        padding: 12px 8px;
        font-size: 12px;
        line-height: 14px;
    }

    @media (max-width: $screen-xs-min - 1) {
        padding: 8px;
    }
}

.ribbon {
    height: 10px;
    position: absolute;
    left: 0;
    right: 0;

    &.top {
        background: #cbcbcb;
        top: 0;
    }

    &:before {
        content: "";
        display: block;
        width: 48.29%;
        height: 10px;
        background: #679dff;
        position: absolute;
    }

    &:after {
        content: "";
        border-style: solid;
        border-width: 8px;
        border-color: #679dff transparent transparent #679dff;
        display: block;
        width: 0;
        height: 0;
        top: 0;
        left: 48.29%;
        position: absolute;
    }

    &.bottom {
        background: #d7d7d7;
        bottom: 0;

        &:before {
            right: 0;
        }
        &:after {
            border-color: transparent #679dff #679dff transparent;
            right: 48.29%;
            left: auto;
            bottom: 0;
            top: auto;
        }
    }
}

.box-block-1 {
    margin-top: -39px;

    .ribbon {
        background: #dfdfdf;
    }

    @media (max-width: $screen-xs-max) {
        margin-top: 0;
    }
}

.box-block-2 {
    > div:nth-child(1) {
        .box {
            background-color: #e0ebff;

            .ribbon {
                background: #c6cfdf;
            }
        }
    }
    > div:nth-child(2) {
        .box {
            background-color: #dfdfdf;

            .ribbon {
                background: #c5c5c5;
            }
        }
    }
}

.page-content {
    position: relative;
    font-size: 14px;

    padding: 20px 40px;
    @media (max-width: $screen-sm-max) {
        margin-top: 0;
        padding: 20px;
    }
    @media (max-width: $screen-sm-min) {
        h1 {
            margin-top: 0;
        }
    }

    h1 {
        margin-top: 0;
        margin-left: -20px;
        margin-bottom: 28px;
        padding: 10px 20px 9px;
        display: inline-block;
        background: #0039a1;
        color: #fff;
        font-weight: bold;
    }
    h2 {
        margin: 27px 0 34px;
        padding: 0 0 8px;
        border-bottom: 1px solid #679dff;
        font-family: 'din_pflbold', Arial, Helvetica, sans-serif;
        font-size: 26px;
        color: #2773fc;
    }

    h1 + h2 {
        margin-top: 9px;

        @media (max-width: $screen-sm-min) {
            margin-top: -10px;
        }
    }

    h3 {
        margin-bottom: 14px;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
    }

    p {
        margin-bottom: 14px;

        &.small {
            margin: 0;
            font-size: 11px;
        }

    }
    ul {
        padding-left: 20px;
        li {
            line-height: 16px;
            margin-bottom: 4px;
            list-style-type: square;

            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    article.item {
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 2px solid #e9e9e9;

        h2 {
            color: #2773fc;

            a {
                text-decoration: none;
                color: #2773fc;
            }
        }
    }
    .sitemap-sub-nav {
        padding-left: 20px;
    }
    .sitemap-nav {
        margin-bottom: 20px;
    }

    table {
        border: 0;
        font-size: 14px;
        width: 100%;

        tr {
            td {
                border: 1px solid #B5B5B5;
                padding: 3px 5px;
                background: #fff;
            }
        }
    }
    table.table-minimal {
        border-collapse: separate;
        border-spacing: 0;
        max-width: 668px;

        tr {
            th {
                padding: 7px 20px 5px;
                border: none;
            }
        }

        tbody {
            tr {
                td, th {
                    padding: 7px 20px 5px;
                    border: none;
                    background: transparent url(./../../public/ui/css/img/table_border.png) left bottom repeat-x;
                    font-size: 14px;
                }

                &:nth-child(2n+1) {
                    background-color: rgba(204,204,204,0.2);
                }

                &:last-child {
                    td, th {
                        background: none;
                    }
                }
            }
        }
        @media (max-width: $screen-xs-min) {
            max-width: 100%;

            tr {
                display: block;

                td, th {
                    display: block;

                    &:nth-child(2) {
                        margin-bottom: 8px;
                        font-weight: bold;
                    }
                }
            }

            tbody {
                tr {
                    td, th {
                        padding: 0 0 2px;
                        background: none;
                    }

                    &:nth-child(2n+1) {
                        background: none;
                    }
                }
            }
        }
    }
}

.no-title .main-container .container-inner .page-content {
    padding-top: 104px;
}

.gyik {
    h2 {
        margin-bottom: 22px;
    }

    h3 {
        margin-top: -12px;
        padding-top: 33px;
        border-top: 2px solid #e9e9e9;
    }

    .accordion-content + .accordion-title {
        margin-top: 16px;
    }

    .accordion-content h3:first-child {
        margin-top: 0;
        padding-top: 0;
        border: none;
    }

    .accordion-content {
        @media (max-width: $screen-sm-min) {
            margin-left: 0;
        }
    }
}

#nd_logo_footer {
    position: relative;
    display: block;
    //margin-right: 15px;
    margin-left: 0;

    @media (max-width: $screen-md-min) {
        padding-top: 18px;
    }
    @media (max-width: $screen-sm-min) {
        margin: 8px 0;

        img {
            max-width: 222px;
            width: 100%;
            height: auto;
        }
    }
}

.accordion-content {
    margin-left: 40px;
}

.accordion-title {
    cursor: pointer;
}

.raktarak-lista {
    a {
        color: #333333;
        text-decoration: none;
        font-weight: bold;

        &:hover, &:active {
            color: #0039a1;
        }
    }
}
.gps {
    color: #7D7D7D;
}

#mapCanvas {
    @media (max-width: $screen-sm-max) {
        display: none;
    }
}

// fololdali hir-csik
.news {
    margin-top: 32px;
    margin-bottom: -20px;
    padding: 7px 24px 7px 16px;
    border: 1px solid #679dff;
    border-radius: 9px;
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.35);
    background: #f0f6ff;
    @include clearfix;
    letter-spacing: 3px;

    @media (max-width: $screen-xs-max) {
        padding-right: 14px;
        letter-spacing: 1px;
    }

    p {
        margin: 0 162px 0 0;
        font-family: dinengschrift, Arial, helvetica, sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: #1249af;

        .news-text-highlighted {
        }
    }

    .news-link-more {
        padding-right: 63px;
        background: url(./../../public/ui/css/img/news_more.png) no-repeat right;
        float: right;
        font-family: dinengschrift, Arial, helvetica, sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: #1249af;
        text-decoration: none;

        @media (max-width: $screen-xs-max) {
            padding-right: 50px;
        }
    }

    @media (max-width: $screen-sm-max) {
        //margin-top: -8px;
    }

    @media (max-width: $screen-xs-max) {
        //margin-top: 0;
        margin-bottom: 24px;
    }

    &.top {
        margin: 0 0 30px 0;
    }
}

.text-highlight {
    color: #0039a1;
}

.form-default {
    .form-control {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        padding: 11px 12px 10px;
        height: auto;
        background-color: #c4d9ff;
        box-shadow: none;
        color: #000;
        border: none;

        &:focus {
            box-shadow: none;
        }
    }
    .form-group {
        margin-bottom: 15px;
        &.captcha {
            margin-top: 5px;
            margin-bottom: 0;
            padding: 20px;
            background-color: #cccccc;
            img {
                display: block;
                margin-bottom: 15px;
            }
            .form-control {
                background-color: #fff;
            }
        }
        &.submit {
            margin-top: 20px;

            a {
                font-size: 12px;
            }
        }
    }

    label {
        margin-bottom: 6px;
        color: #000;
        font-weight: normal;
        font-size: 14px;
    }
}

.one-row-buttons {
    display: inline-block;
}

.btn {
    &.btn-default {
        background: #679dff;
        border: none;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        color: #fff;
        padding: 10px 36px 10px;
        height: auto;
        font-size: 14px;
        line-height: 20px;
        margin-right: 15px;
    }
}
span.error {
    color: red;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 10px;

    h1 + & {
        display: block;
    }
}
.block-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 27px;
    margin-bottom: 7px;

    &.first {
        margin-top: 0;
    }
}

/* reg. */
.page-content table.default, .page-content table.general.default {
    border: 0;
    tr {

        th {
            background: #e7e7e7;
            border: 0;
            border-bottom: 1px solid #b5b5b5;
            height: 52px;
        }

        td, th.th-scope-row {
            border: 0;
            border-bottom: 1px solid #B5B5B5;
            height: 52px;
        }

        th.th-scope-row {
            background: #fff;
        }
    }
}

.page-content table.general {
    width: 100%;
    th {
        padding: 15px 7px;
    }
    td, th.th-scope-row {
        padding: 11px 7px;
        font-size: 12px;
        background: #fff;
    }
    th.title {
        width: auto;
        font-size: 14px;
        padding-left: 7px;
        text-align: center;
    }
    a {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
    tr:hover td {
        background: #fff !important;
        cursor: inherit;
    }
}

.page-content table.general.datasheet,
.page-content table.datasheet {
    .th-scope-row {
        width: 390px;
        font-weight: normal;

        @media (max-width: $screen-sm-min) {
            width: auto;
        }
    }

    td {
        font-weight: bold;
    }
}

.box-reg {
    label {
        font-size: 13px;
    }
}

.form-group.phrasing {
    margin-left: 20px;

    [type="checkbox"] + label, [type="radio"] + label {
        margin-left: 4px;
    }
    [type="checkbox"], [type="radio"] {
        margin-left: -20px;
        vertical-align: top;
    }
}

.type-change {
    padding: 0;

    .box {
        width: auto;
        cursor: pointer;

        @media (max-width: $screen-md-max) {
            margin-bottom: 40px;
        }

        .box-title {
            height: 8px;
        }

        .box-content {
            height: auto;
            min-height: 46px;
            font-size: 18px;
            line-height: 22px;

            @media (max-width: $screen-md-max) {
                min-height: 68px;
            }

            @media (max-width: $screen-xs-max) {
                min-height: 0;
            }
        }

        button {
            display: none;
        }
    }
}
span.hl {
    color: #0039a1;
}

.carrier-item {
    border-bottom: 2px solid #e9e9e9;
    margin-bottom: 20px;
    padding-bottom: 10px;

    &:last-child {
        border: none;
    }
}
.jobs-list {
    margin-bottom: 30px;
}
.flex-box {
    display: -webkit-flex;
    display: flex;
    display: -ms-flex;
    -webkit-flex-flow: row;
    -ms-flex-flow: row;
    flex-flow: row;

    .box {
        position: relative;
        &.left {
            margin-right: 28px;
        }
        .read-more {
            position: absolute;
            right: 20px;
            bottom: 20px;
        }
    }
}

.facebook {
    &-box {
        float: left;
        margin-right: 15px;
        max-width: 100%;

        @media all and (max-width: $screen-xs) {
            margin: 0;
        }

        &.header {
            margin: 0;
            float: none;
            min-height: 22px;
        }
    }
}
